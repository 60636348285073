import React, { useContext } from "react";
import CustomAuth from "../../components/custom-auth";
import SEO from "../../components/seo";
import { UserContext } from "../../provider/usercontextprovider";
import EnrollmentForm from "../../components/enrollment-form";
import { UserTenantProvider } from "../../provider/usertenantprovider";

const ProtectedPage = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const isEnroller = userContext.username &&
        userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantEnroller');

    return (
        <CustomAuth>
            <SEO title="Enrollments" />
            <UserTenantProvider>
              {isEnroller ? <EnrollmentForm userContext={userContext} /> : <p>User is not an enroller</p>}
            </UserTenantProvider>
        </CustomAuth>)
}

export default ProtectedPage
