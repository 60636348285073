import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Select from "react-select";
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import * as enrollerStyles from "./enroller.module.css";
import * as buttonStyles from "./button.module.css";
import { API, graphqlOperation } from "aws-amplify";
import { LoadMaskContext } from "../provider/loadmaskprovider";
import { Notification, NotificationType } from "./notification";
import { UserTenantContext } from "../provider/usertenantprovider";

const listMedicalTestsByTenantIdAndEnrolleeIdentifier = /* GraphQL */ `
query ListMedicalTestsByTenantIdAndEnrolleeIdentifier(
    $tenantId: String!,
    $enrolleeIdentifier: String!,
) {
    listMedicalTestsByTenantIdAndEnrolleeIdentifier(
        tenantId: $tenantId
        enrolleeIdentifier: {eq: $enrolleeIdentifier}
    ) {
        items {
            id
            enrolleeIdentifier
            sampleCode
            enrolleeEmailAddress
        }
    }
}`

const updateMedicalTest /* GraphQL */ = `
mutation UpdateMedicalTest(
    $input: UpdateMedicalTestInput!
) {
    updateMedicalTest(input: $input) {
        id
    }
}`

const EnrollmentForm = ({ userContext }) => {
    const [isLoading, setIsLoading] = useContext(LoadMaskContext);
    const [panelTypeList, setTestTypeList] = useState([
        { value: 'RESPI507', label: 'COVID-19 Coronavirus (SARS-CoV-2)' },
    ]);

    const [sampleSourceList, setSampleSourceList] = useState([
        { value: 'nasopharynx', label: 'Nasopharynx' }
    ]);
    const [clientSite, setClientSite] = useState(null);
    const [enrollmentConfigList, setEnrollmentConfigList] = useState([]);
    const [tenant, setTenant] = useState(null);
    const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext);
    const options = userTenantContext.filter(t => t.tenantRole.includes("Admin")).map(tenant => {
        return {
            value: tenant.tenantObj.name,
            label: tenant.tenantObj.prettyName,
        }
    });
    const onTenantChange = e => setTenant(e);
    const emptyTest = {
        enrolleeEmailAddress: ""
    }
    const [currentTest, setCurrentTest] = useState(emptyTest);
    const [message, setMessage] = useState({
        show: false
    });
    const [collectionMethodList] = useState([
        { value: "ENROLLER", label: "Enroller" },
        { value: "SELF", label: "Self" },
    ]);

    let timer = void 0;
    const debounce = fn => {
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn(...args);
            }, 500);
        }
    }

    let getTestList = (enrolleeIdentifier) => {
        if (enrolleeIdentifier === "" || tenant == null) {
            setMessage({
                show: false
            });
        } else {
            setIsLoading(true);
            if (JSON.stringify(currentTest) !== JSON.stringify(emptyTest)) {
                setCurrentTest(emptyTest);
            }
            try {
                async function fetch() {
                    const result = await API.graphql(
                        graphqlOperation(listMedicalTestsByTenantIdAndEnrolleeIdentifier, {
                            tenantId: tenant.value,
                            enrolleeIdentifier: enrolleeIdentifier,
                        })
                    );
                    let testToUpdate = result.data.listMedicalTestsByTenantIdAndEnrolleeIdentifier.items.find(({ sampleCode }) => sampleCode === null);
                    setCurrentTest(testToUpdate);
                    setMessage({
                        show: true,
                        msg: testToUpdate ? 'Successfully loaded record' : 'Record not found',
                        messageType: testToUpdate ? NotificationType.SUCCESS : NotificationType.FAIL
                    });

                }
                fetch();
            } finally {
                setIsLoading(false);
            }
        }
    }

    getTestList = debounce(getTestList);

    const enroll = async () => {
        setIsLoading(true);
        try {
            await API.graphql(
                graphqlOperation(updateMedicalTest, { input: currentTest })
            );
            setMessage({
                show: true,
                msg: 'Successfully updated medical record',
                messageType: NotificationType.SUCCESS
            });
        } catch {
            setMessage({
                show: true,
                msg: 'Failed to update medical record',
                messageType: NotificationType.FAIL
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h2>Enrollment Form</h2>
                {userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('Manager') &&
                userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantEnroller') &&
                <div>
                    <Link className={buttonStyles.button} to="/manager/medicallookup">Find a test</Link>
                    <Link className={buttonStyles.button} to="/manager/add-medical-test">Create a test</Link>
                </div>}
            </div>
            { message.show && 
            <Notification messageType={message.messageType}>
                <p>{message.msg}</p>
            </Notification> }
            <div className={enrollerStyles.form}>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="clientSite">Tenant</label>
                    <Select options={options} onChange={onTenantChange} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="enrolleeIdentifier">Enrollee ID</label>
                    <input className={enrollerStyles.input} type="text" name="enrolleeIdentifier" disabled={!tenant} onChange={e => getTestList(e.target.value)} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="clientSite">Collection Method</label>
                    <Select options={collectionMethodList} isDisabled={!currentTest?.id} onChange={e => {setCurrentTest({...currentTest, collectionMethod: e.value})}} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="enrolleeIdentifier">Enrollee Email Address</label>
                    <input className={enrollerStyles.input} type="email" name="enrolleeEmailAddress" value={currentTest?.enrolleeEmailAddress == null ? "" : currentTest.enrolleeEmailAddress} disabled={!currentTest?.id} onChange={e => setCurrentTest({...currentTest, enrolleeEmailAddress:e.target.value})} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="panelName">Panel Name</label>
                    <Select options={panelTypeList} isDisabled={!currentTest?.id} onChange={e => {setCurrentTest({...currentTest, panelName:e.label, panelCode:e.value})}} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="sampleCode">Sample Code</label>
                    <input className={enrollerStyles.input} disabled={!currentTest?.id} type="text" name="sampleCode" onChange={e => setCurrentTest({...currentTest, sampleCode: e.target.value})} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="sampleSource">Sample Source</label>
                    <Select options={sampleSourceList} isDisabled={!currentTest?.id} onChange={sampleSource => setCurrentTest({...currentTest, sampleSource:sampleSource.value})} />
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="dateOfCollection">Collection Date</label>
                    <DayPicker className={enrollerStyles.input} value={currentTest?.dateOfCollection} inputProps={{ disabled: !currentTest?.id }} onDayChange={day => setCurrentTest({...currentTest, dateOfCollection: day})} />
                    <style>
                        {`
                        .DayPicker {
                            width: 100%;
                        }
                        .DayPicker input {
                            background-color: rgb(255, 255, 255);
                            min-height: 38px;
                            box-sizing: border-box;
                            border-color: rgb(204, 204, 204);
                            border-radius: 4px;
                            border-style: solid;
                            border-width: 1px;
                            padding: 0.5rem;
                            width: 100%;
                        }
                    `}
                    </style>
                </div>
                <div className={enrollerStyles.inputWrapper}>
                    <label className={enrollerStyles.label} htmlFor="collectedBy">Collector</label>
                    <input className={enrollerStyles.input} type="text" name="collectedBy" disabled={!currentTest?.id} onChange={e => setCurrentTest({...currentTest, collectedBy:e.target.value})} />
                </div>
            </div>
            {currentTest?.collectionMethod == 'ENROLLER' && <div className={enrollerStyles.inputWrapper}>
                <label>
                    <input type="checkbox" onChange={e => setCurrentTest({...currentTest, consent: e.target.checked})} />
                    <span style={{ marginLeft: "6px" }}>Consent obtained</span>
                </label>
            </div>}
            <div className={enrollerStyles.inputWrapper}>
                <button
                    type="button"
                    className={buttonStyles.button}
                    disabled={
                        !currentTest?.panelName ||
                        !currentTest?.id ||
                        !currentTest?.sampleCode ||
                        !currentTest?.sampleSource ||
                        !tenant ||
                        !currentTest?.dateOfCollection ||
                        !currentTest?.collectedBy ||
                        !currentTest?.collectionMethod ||
                        (currentTest?.collectionMethod == "SELF" && !currentTest?.enrolleeEmailAddress) ||
                        (currentTest?.collectionMethod == "ENROLLER" && !currentTest?.consent)
                    }
                    onClick={enroll}
                >
                    Enroll
                </button>
            </div>
        </div>
    );
}

export default EnrollmentForm;